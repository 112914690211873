.topBar {
  display: none;
  @include breakpoint($break) {
    &.scroll {
      padding: rem(10px) rem($baseGap);
      font-size: rem(10px);
      transition: 300ms linear;
      .h6 {
        font-size: rem(12px);
        margin-bottom: rem($baseGap / 4);
      }
      @include breakpoint(huge) {
        .h6 {
          font-size: rem(14px);
          margin-bottom: rem($baseGap / 4);
        }
        font-size: rem(12px);
      }
    }
    display: block;
    position: fixed;
    background-color: $dark;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
    padding: rem(40px) rem($baseGap);
    font-size: rem(14px);
    transition: 300ms linear;
  }
  @include breakpoint(huge) {
    font-size: rem(16px);
  }
  .border {
    width: 1px;
    flex-basis: 1px;
    height: rem(80px);
    display: inline-block;
    background: rgba($light, 0.3);
    position: relative;
  }
  .contact {
    // @include breakpoint(giant) {
    // 	margin-left: 5%;
    // }
    @include breakpoint(huge) {
      margin-left: 10%;
    }
    @include breakpoint(cut) {
      margin-left: 15%;
    }
  }
  .socialMedia {
    display: flex;
    width: rem(70px);
    justify-content: space-between;
    align-items: center;
    float: right;
    margin-left: rem(10px);
    min-width: 48%;
    @include breakpoint(huge) {
      width: rem(80px);
    }
    .icon-fb {
      color: #3578e5;
      font-size: rem(20px);
      &:hover {
        color: darken(#3578e5, 15%);
      }
    }
    .icon-ig {
      color: #ff0084;
      font-size: rem(22px);
      &:hover {
        color: darken(#ff0084, 15%);
      }
    }
    .icon-yt {
      color: #ff0000;
      font-size: rem(25px);
      &:hover {
        color: darken(#ff0000, 15%);
      }
    }
  }
}
#botBar {
  @include breakpoint(giant, max) {
    display: none;
  }
  width: 100%;
  background: $dark;
  padding: 18px 0;
  top: 88px;
  @include breakpoint(full) {
    top: 108px;
  }
  @include breakpoint(cut) {
    top: 118px;
  }
  position: fixed;
  z-index: 10;
  display: flex;
  border-bottom: 1px solid $medium;
  .row {
    justify-content: center;
  }
  img {
    max-width: 200px;
  }
  opacity: 0;
  &.scroll {
    opacity: 1;
  }
}

body:not(.index) {
  #header {
    display: none;
  }
  .contentContainer {
    @extend .padding;
    margin-top: rem($barHeight);
    @include breakpoint(giant) {
      margin-top: rem(188px);
    }
    @include breakpoint(full) {
      margin-top: rem(148px);
    }
    @include breakpoint(cut) {
      margin-top: rem(188px);
    }
  }
  .contactBar {
    padding-top: 0 !important;
  }
}

body.cmsBackend {
  #header,
  #footer,
  .contactBar {
    display: none;
  }
  #navigation {
    top: 0;
  }
}
body.tanzkurse {
  .teaserImageSwiper {
    margin-top: 0;
  }
}

// HEADER
//////////////////////////////

#header {
  @include responsiveImage('layout/bgHeader.jpg', (small, large, huge), false);
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  margin-top: rem($barHeight);
  @include breakpoint(giant) {
    margin-top: rem(208px);
  }
  @include breakpoint(huge) {
    margin-top: rem(168px);
  }
  @include breakpoint(full) {
    margin-top: rem(188px);
  }
  @include breakpoint(cut) {
    margin-top: rem(208px);
  }
  .claimContainer {
    padding: rem(80px) 0 rem(160px);
    @include breakpoint(large) {
      padding: rem(150px) 0 rem(300px);
    }
    @include breakpoint(huge) {
      padding: rem(250px) 0 rem(350px);
    }
    .first {
      margin-right: rem(10px);
    }
  }
}

.claim {
  text-shadow: 2px -4px 3px rgba($dark, 1);
  color: $light;
  text-transform: uppercase;
  font-weight: 900;
  font-size: rem(28px);
  line-height: rem(45px);
  margin-bottom: rem(30px);
  @include breakpoint(small) {
    font-size: rem(35px);
    line-height: rem(45px);
  }

  @include breakpoint(giant) {
    font-size: rem(55px);
    line-height: rem(65px);
    margin-bottom: rem(50px);
  }
  span {
    font-weight: 300;
    text-transform: none;
    display: block;
    font-size: rem(22px);
    line-height: rem(30px);
    @include breakpoint(small) {
      line-height: unset;
    }
    @include breakpoint(giant) {
      font-size: rem(35px);
    }
  }
}

// MAIN CONTENT
//////////////////////////////

main {
  display: block;

  p {
    @extend .marginBottom;
    word-break: break-word;
  }
  ol,
  ul,
  dl,
  table {
    @extend .marginBottom;
  }

  ul:not(.unstyledList),
  dl:not(.unstyledList),
  ol:not(.unstyledList) {
    @extend .styledList;
  }

  img {
    margin-bottom: rem($baseGap * 2);
  }

  #curator-feed-11816435-spotlight-dancecenterat-la {
    max-width: rem(900px);
    margin: 0 auto;
  }
}

#privacyPolicy {
  a {
    display: inline-block;
    word-break: break-all;
  }
}

// Mobile Teaser Slider / Desktop Image+Text Teaser
.teaserImageSwiper {
  width: 100%;
  margin-top: rem(-80px);
  @include breakpoint(large) {
    margin-top: rem(-150px);
  }
  @include breakpoint(huge) {
    margin-top: rem(-150px);
  }
  .swiper-wrapper {
    @include breakpoint(huge) {
      @include row;
      height: auto;
    }

    .swiper-slide {
      position: relative;
      height: auto; // auto = alle gleich hoch, 100% = Element HÃ¶he

      &.huge-3 {
        @include breakpoint(huge) {
          @include col3;
        }
      }

      .teaser {
        display: inline-block;
        background: $medium;
        position: relative;
        transition: 300ms linear;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: block;
          transition: 300ms linear;
          background: rgb(255, 255, 255);
          background: -webkit-linear-gradient(
            bottom,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.5802696078431373) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -o-linear-gradient(
            bottom,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.5802696078431373) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.5802696078431373) 50%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .inner {
          position: absolute;
          bottom: 0;
          z-index: 1;
          padding: 0 0 rem(20px) rem(20px);
          @include breakpoint(huge) {
            padding: 0 0 rem(30px) rem(30px);
          }
          .title {
            color: $dark;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: rem(25px);
            line-height: rem(35px);
            @include breakpoint(huge) {
              font-size: rem(28px);
            }
            @include breakpoint(full) {
              font-size: rem(30px);
            }
            span {
              display: block;
              text-transform: none;
              font-weight: 300;
              font-size: rem(20px);
            }
          }
        }
        img {
          margin-bottom: 0;
        }

        &:hover {
          &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            display: block;
            background: rgb(246, 231, 19);
            background: -webkit-linear-gradient(
              bottom,
              rgba(246, 231, 19, 1) 0%,
              rgba(246, 231, 19, 0.19931722689075626) 50%,
              rgba(246, 231, 19, 0) 100%
            );
            background: -o-linear-gradient(
              bottom,
              rgba(246, 231, 19, 1) 0%,
              rgba(246, 231, 19, 0.19931722689075626) 50%,
              rgba(246, 231, 19, 0) 100%
            );
            background: linear-gradient(
              to top,
              rgba(246, 231, 19, 1) 0%,
              rgba(246, 231, 19, 0.19931722689075626) 50%,
              rgba(246, 231, 19, 0) 100%
            );
          }
        }
      }
    }
  }
}
.aktuellesContainer {
  h1 {
    color: $dark;
  }
  .aktuellesBox {
    color: $dark;

    @include breakpoint($break) {
      margin-top: rem(50px);
      display: inline-block;
    }

    .ewcms_news_feed {
      @include breakpoint(large) {
        display: flex;
        justify-content: space-between;
      }

      div {
        width: 100%;
        max-width: 100% !important;
        @include breakpoint(large) {
          width: calc(50% - #{rem($baseGap)});
          float: left;
          max-width: 50% !important;
        }

        span {
          @extend .h3;
          color: $dark;
        }
        p {
          color: $dark;
          &:after {
            content: '[...]';
            margin-left: rem(10px);
          }
        }
        .ewcms_news_link {
          @extend .btn;
          span {
            text-transform: none;
            font-weight: 700;
            line-height: rem($baseLineHeight);
          }
        }
      }
    }
  }
}

.banner {
  margin: 80px 0;
  @include breakpoint(full) {
    margin: 100px 0;
  }
  /*padding: rem(80px) 0;
	@include breakpoint(large) {
		padding: rem(150px) 0;
	}
	@include breakpoint(huge) {
		padding: rem(250px) 0;
	}
	@include responsiveImage('index/bgBanner.jpg', (small, large, huge), true);
	&.lazyLoaded {
		background-position: center;
		background-size: cover;
	}*/
}
.onlineDance {
  width: 100%;
  @include breakpoint(tiny) {
    max-width: rem(180px);
    float: right;
    margin: 0 0 rem(30px) rem(30px);
  }
  @include breakpoint(huge) {
    max-width: rem(250px);
  }
}

.box {
  background: #343434;
  padding: rem($baseGap * 2) rem($baseGap);
  @include breakpoint(medium) {
    min-height: rem(300px);
  }
  @include breakpoint(large) {
    min-height: unset;
  }
  @include breakpoint(huge) {
    min-height: rem(344px);
  }
  @include breakpoint(full) {
    min-height: unset;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $light;
  margin-bottom: rem($baseLineHeight);
  @include breakpoint(small) {
    padding: rem(25px);
  }
  @include breakpoint(large) {
    margin-bottom: 0;
  }
  @include breakpoint(giant) {
    padding: rem(60px) rem(40px);
    height: 100%;
  }
  ul.payment {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex-basis: 100%;
      width: 100%;
      @include breakpoint(tiny) {
        flex-basis: 50%;
        width: 50%;
      }
      @include breakpoint(small) {
        flex-basis: 33.3333%;
        width: 33.3333%;
      }
      @include breakpoint(giant) {
        flex-basis: 50%;
        width: 50%;
      }
      @include breakpoint(full) {
        flex-basis: 33.3333%;
        width: 33.3333%;
      }
    }
  }

  .times {
    margin-bottom: rem(10px);
    span {
      display: block;
      @include breakpoint(tiny) {
        display: inline-block;
      }
    }
  }
  .lighter {
    color: rgba($dark, 0.8);
  }
}
// FOOTER
//////////////////////////////

#footer {
  background: $light;
  margin: 0 0 rem(67px);
  padding: rem($baseGap) 0;
  @include breakpoint(giant) {
    margin: 0;
  }
}

.agbSection {
  p {
    padding-left: rem(55px);
    position: relative;
    @include breakpoint(giant) {
      padding-left: rem(65px);
    }

    span {
      position: absolute;
      left: 0;
    }

    &.h2 {
      padding-left: 0;
    }
  }
  .spacerLeft {
    @include breakpoint(giant) {
      padding-left: rem(70px);
    }
  }
}

.info {
  body:not(.cmsBackend) & {
    .tooltipcontent {
      border: 2px solid $secondary;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      background: $dark;
      color: $light;
      height: 80vh;
      width: 70vw;
      opacity: 0;
      visibility: hidden;
      transition: 300ms;
      z-index: 9;
      overflow-y: scroll;
      padding: rem(50px) rem(20px);
      font-size: rem(14px);

      @include breakpoint(large) {
        font-size: rem(16px);
        padding: rem(80px) rem(50px);
        height: 60vh;
      }
      @include breakpoint(full) {
        font-size: rem($baseFontSize);
        height: 50vh;
      }

      &.active {
        transform: translate(-50%, -50%);
        opacity: 1;
        visibility: visible;
      }

      button {
        width: rem(30px);
        height: rem(30px);
        background: #f6e713;
        position: fixed;
        top: 0;
        right: 0;
        border: 0;
        color: #1a1a1a;
        font-size: rem(20px);
        @include breakpoint(large) {
          width: rem(50px);
          height: rem(50px);
          font-size: rem(30px);
        }
      }
    }
  }
}

.member {
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  margin-bottom: rem($baseLineHeight);
  img {
    width: auto;
  }
  .info {
    background: #343434;
    color: $light;
    padding: rem($baseGap * 2) rem($baseGap);
    // display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    @include breakpoint(medium) {
      padding: rem($baseGap * 2);
    }
    @include breakpoint(large) {
      padding: rem($baseGap * 2) rem($baseGap);
    }
    @include breakpoint(giant) {
      padding: rem(40px) rem($baseGap * 2);
    }
    @include breakpoint(huge) {
      padding: rem(40px) rem($baseGap);
    }
    .btn {
      &:after {
        @include breakpoint(large) {
          padding: rem(30px) rem(18px);
        }
        @include breakpoint(giant) {
          padding: rem(30px);
        }
      }
    }

    .popupMember {
      position: relative;
      bottom: 0;
      margin-bottom: 0;
      color: $primary;
      cursor: pointer;
      transition: 300ms linear;
      span.btnName {
        padding: 0px;
        font-size: 1.11111rem;
        font-weight: 550;
      }
      // span.btn1{
      // 	color:$dark;
      // 	// padding: rem(20px) rem(5px);
      //  //  	@include breakpoint(small) {
      //     // 	padding: rem(20px) rem(10px);
      //     // }
      // 	display: flex;
      // 	flex-flow: column;
      // 	font-weight: 450;
      // 	margin-bottom: 12px;
      // 	&:hover {
      // 		color: $light;
      // 	}
      // }
      &:hover {
        color: $dark;
      }
    }
  }
  .fitcontent {
    height: fit-content !important;
  }
}

.branding {
  margin: 120px rem(30px) 0;
  width: auto;

  @include breakpoint(large) {
    position: absolute;
    margin: rem(70px) rem($baseGap) 0;
  }

  img {
    max-width: 250px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}
div.bigBranding {
  margin-top: rem(30px);
  margin-bottom: rem(60px);
  max-width: rem(600px);
  @include breakpoint(small) {
    margin-top: 0px;
  }
  @include breakpoint(large) {
    margin-bottom: rem(120px);
  }
}

span.phoneIcon {
  &:before {
    @extend .icon;
    @extend .icon-phone;
    margin-right: 5px;
  }
}
span.emailIcon {
  &:before {
    @extend .icon;
    @extend .icon-mail;
    margin-right: 5px;
  }
}

.mediumSpacerTwo {
  margin-top: rem(50px);
}
.mediumSpacerImages {
  margin-bottom: rem(18px);
  @include breakpoint(small) {
    margin-bottom: rem(36px);
  }
}

body.cmsBackend {
  .teaserImageSwiper {
    display: none;
  }
}

body.cmsBackend .popup-cms {
  background-color: #272727;
}
body:not(.cmsBackend) .popup-cms {
  p.h2 {
    word-break: break-all;
  }
  z-index: 1000;
  position: fixed;
  max-height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 13px;
  height: auto;
  width: auto;
  background-color: white;
  padding: 30px 15px;
  color: #101010;
  line-height: 30px;
  ms-flex-wrap: wrap;
  flex-wrap: wrap;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 90%;
  border-radius: 7px;
  border: solid 3px $primary;
  -moz-box-shadow: 0px 0px 24px -4px #333;
  -webkit-box-shadow: 0px 0px 24px -4px #333;
  box-shadow: 0px 0px 24px -4px #333;
  @include breakpoint(tiny) {
    padding: 35px 35px;
  }
  @include breakpoint(medium) {
    width: 80%;
  }
  .popup-content {
    color: rgb(67, 67, 67);
    font-size: 18px;
    font-weight: 400;
    font-family: $mainFont;
    display: block;
  }

  p.h2 {
    color: #101010;
    font-size: 22px;
    transform: translate(20, 124);
    font-family: $mainFont;
    font-weight: 400;
  }
  .popupCloseButton {
    background-color: $primary;
    border-radius: 0px 6px 0px 10px;
    border: 0px;
    cursor: pointer;
    font-weight: thin;
    position: absolute;
    color: black;
    top: -2px;
    right: -2px;
    font-size: 15px;
    line-height: 1em;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    transition: cubic-bezier(0.22, 0.61, 0.36, 1) all 200ms;
    @include breakpoint(small) {
      width: 40px;
      height: 40px;
    }
    @include breakpoint(huge) {
      width: 50px;
      height: 50px;
    }
    &:hover {
      font-weight: 400;
      color: darken($primary, 25%);
    }
  }
}

body.index .aktuellesTeaser {
  height: 200px;
  @include breakpoint(tiny) {
    height: 280px;
  }
  @include breakpoint(huge) {
    height: 300px;
  }
  overflow: hidden;
  padding: 15px 30px;
  margin-bottom: 25px;
  position: relative;
  @include breakpoint(huge) {
    padding: 15px 50px;
  }
  &:before:not(.cmsBackend) {
    content: '';
    width: 80%;
    height: 15%;
    background-color: rgba(39, 39, 39, 0.8);
    position: absolute;
    bottom: 0px;
    left: 25px;
    filter: blur(8px);
  }

  p {
    .h3 {
      font-size: 18px;
      span {
        font-size: 16px;
      }
    }
    font-size: 14px;
    @include breakpoint(giant) {
      font-size: rem($baseFontSize);
      h3 {
        font-size: rem(22px);
        line-height: rem(30px);
        span {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

div.aktuellesTeaserFlex {
  display: flex;
  justify-content: flex-start;
}
body.cmsBackend {
  .aktuellesTeaserBox {
    position: static;
  }
}
div.aktuellesTeaserBox {
  .eins {
    margin: 30px 0;
    @include breakpoint(small) {
      margin: 0px;
    }
    .roundAktuelles {
      background: #272727;
      border-radius: 100%;
      display: flex;
      align-items: center;
      padding: 30px 35px 30px 30px;
      height: rem(290px);
      width: rem(290px);
      margin: 0 auto;
      @include breakpoint(tiny) {
        width: rem(420px);
        height: rem(420px);
        padding: 30px 48px 30px 48px;
      }
      @include breakpoint(small) {
        width: rem(460px);
        height: rem(460px);
        padding: 30px 54px 30px 48px;
      }
      @include breakpoint(medium) {
        width: rem(350px);
        height: rem(350px);
        padding: 30px 54px 30px 48px;
      }
      @include breakpoint(giant) {
        width: rem(500px);
        height: rem(500px);
      }
      @include breakpoint(cut) {
        width: rem(550px);
        height: rem(550px);
      }
    }
  }
  .zwei {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

body.raumVermietungPhp {
  .fancyFlex {
    margin-bottom: 15px;
    @include breakpoint(large) {
      margin-bottom: 25px;
    }
    display: flex;
    justify-content: flex-start;
    div.checkbox {
      margin-bottom: 0px;
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
}
body p.collapserHeadline {
  margin-bottom: 0px;
  color: $dark;
  font-weight: 550;
  text-transform: uppercase;
}
.collapserDropDown {
  margin-top: 15px;
}

p.NoMB {
  margin-bottom: 0px;
}
p.NoMB1 {
  margin-bottom: 5px;
}
div.NoPL {
  padding-left: 0px;
  padding-right: 0px;
  @include breakpoint(large) {
    padding-right: 18px;
  }
}

.firstBtn {
  @include breakpoint(large) {
    margin-right: rem(30px);
  }
}

.respMarg {
  margin-top: rem(30px);
  @include breakpoint(small) {
    margin-top: rem(0px);
  }
  @include breakpoint(large) {
    margin-top: rem(30px);
  }
  @include breakpoint(giant) {
    margin-top: rem(0px);
  }
}

.contactBar {
  img {
    max-width: rem(300px);
    width: 100%;
    @include breakpoint(full) {
      max-width: rem(350px);
    }
  }
}

.WidgetBanner {
  @include breakpoint(small) {
    bottom: 50%;
  }
}

//////////// popup

.editContainer {
  background: $medium;
  padding: rem(30px);
}
#popUpContainer {
  display: none;
  overflow-y: scroll;

  a {
    color: #a99e0c;
  }

  &.isVisible {
    display: block;
    &.closed {
      display: none;
    }
  }
  #popup {
    max-width: 800px;
    padding: 30px;
    background: $light;
    width: 80%;
    color: $dark;

    @include breakpoint(large) {
      width: 100%;
    }

    .close {
      background: transparent;
      border: 0px;
      top: -20px;
      right: -20px;
    }
  }
}

.overlayContainer {
  position: fixed;
  background: rgba($dark, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;

  .addOn {
    position: absolute;
    left: 50%;
    top: 19%;
    transform: translateX(-50%);
    @include breakpoint(medium) {
      top: 30%;
    }

    .close {
      position: absolute;
      top: -40px;
      right: -40px;
      z-index: 999;

      transition: 0.5s;

      &:before {
        transition: 0.5s;
        @extend .icon;
        width: rem(40px);
        height: rem(40px);
        border-radius: 20px;
        background: $primary;
        color: $dark;
        text-align: center;
        line-height: 40px;
        display: inline-block;
        @extend .icon-times;
        background: $primary;
      }
      &:hover {
        cursor: pointer;
        &:before {
          background: $secondary;
        }
      }

      span {
        display: none;
      }
    }
  }
}
iframe.dancecloudFrame {
  height: 550px;
  body {
    background-color: red !important;
  }
}
.anmelden {
  margin-top: -100px;
  @include breakpoint(large) {
    margin-top: -150px;
  }
}
body.aktuellesEvents {
  .contentContainer {
    padding-top: 0 !important;
  }
  .aktuellesBox {
    padding-top: rem(80px);
    @include breakpoint(huge) {
      padding-top: rem(100px);
    }
    @include breakpoint(full) {
      padding-top: rem(150px);
    }
  }

  .marginHr {
    margin-top: rem(80px);
    @include breakpoint(huge) {
      margin-top: rem(100px);
    }
    @include breakpoint(full) {
      margin-top: rem(150px);
    }
  }
}

.WidgetBannerContainer {
  z-index: 915 !important;

  @include breakpoint(large, max) {
    .WidgetPopup {
      margin-top: 50px;
    }
  }
}
.WidgetPopup {
  @include breakpoint (giant, max) {
    @include breakpoint (large) {
      top: rem(50px);
    }
  }
}

body.agb {
  #content {
    .contentContainer {
      font-size: rem(14px);
      @include breakpoint(giant) {
        font-size: rem(16px);
      }

      .line {
        position: relative;
        display: inline-block;
        height: rem($baseLineHeight * 2);
        display: block;
        @include breakpoint(cut) {
          display: inline-block;
          margin-left: rem(15px);
          height: rem($baseLineHeight);
        }
        &:before {
          content: '';
          width: rem(200px);
          background: rgba($light, 0.6);
          height: rem(1px);
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          @include breakpoint(cut) {
            width: rem(350px);
          }
        }
      }

      dl {
        @extend .spacerLeft;
        display: block;
        dt,
        dd {
          background: transparent !important;
          padding: 0 !important;
          margin: 0 !important;
          float: left;
        }
        dt {
          width: 100%;
          @include breakpoint(small) {
            width: rem(120px);
          }
        }
        dd {
          width: 100%;
          margin-bottom: rem(15px) !important;
          @include breakpoint(small) {
            width: calc(100% - 120px);
          }
        }
      }
      .shortLine {
        position: relative;
        display: inline-block;
        height: rem($baseLineHeight);
        display: block;
        @include breakpoint(small) {
          display: inline-block;
          margin-left: rem(15px);
        }
        &:before {
          content: '';
          width: 100%;
          background: rgba($light, 0.6);
          height: rem(1px);
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          @include breakpoint(small) {
            width: rem(200px);
          }
        }
      }
    }
  }
}

.instagramicon {
  height: 35px;
  width: 35px;
  margin: 0 1rem 0 0;
  @include breakpoint(large) {
    margin: 0 1rem 5px 0;
  }
}

.instagramiframe {
  background-color: white;
  display: block;
  box-shadow: 9px 5px 11px 0px black;
}

// BaguetteBox Styles abändern (Stundenplan)
body.stundenplanTermine {
  #baguetteBox-overlay {
    overflow: auto;
    .full-image {
      &:nth-child(1) {
        img {
          height: auto !important;
          max-height: none !important;
          overflow: auto;

          @include breakpoint(small) {
            padding: 3rem;
          }
          @include breakpoint(giant) {
            padding: 4.5rem;
          }
        }
      }
      figcaption {
        display: none;
      }
    }
  }
}

body.datenschutzerklaerung #pageWrap {
  overflow: hidden;
}

.flx {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .newsletter {
    margin-top: 3rem;
    text-align: center;
    .border {
      border: 1px solid #797979;
      width: 100%;
      z-index: 999999999999;
      padding: 0.2rem;
    }
    iframe {
      border: none;
    }
  }
}

.WidgetBannerContainer {
  .WidgetBanner {
    display: block !important;
  }
}

.WidgetBannerContainer {
  .widgetFlex {
    display: none;
  }
}

.partnerbar {
  padding: 0 $baseGap;

  .row {
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      img {
        margin-bottom: 0;
        filter: drop-shadow(0px 0px 5px #000);
      }
    }
  }
}
body.index {
  .newsletter {
    iframe.nl-form {
      height: 27rem;
    }
  }
}
.googleMaps {
  margin-bottom: rem(20px);
  @include breakpoint(small) {
    margin-bottom: rem(40px);
  }
  @include breakpoint(large) {
    margin-bottom: rem(60px);
  }
  @include breakpoint(giant) {
    margin-bottom: 0;
  }
}
.mitte {
  justify-content: center;
  align-items: center;
}
iframe.raumcloudFrame {
  height: 950px;
  body {
    background-color: red !important;
  }
}
body.pcOpenFirst.privacyControlIsOpen {
  .notificationArea {
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: 1101;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: visible;
    visibility: visible;
    opacity: 1;
    background-color: rgba($dark, 0.7);
    position: absolute;
    z-index: 1100;
    left: 0;
    top: 0;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  margin-bottom: $baseGap;
}
.aktuellesBox {
  &:has(.hidden) {
    padding-top: 0 !important;
  }
}
.schulprojekteHeading {
  display: flex;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
}