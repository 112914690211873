// Use this file for your custom mixins/functions

@mixin row {
	margin-left: auto;
	margin-right: auto;
	max-width: rem($rowMaxWidth);
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
}

@mixin col3 {
	width: 100%;
	max-width: calc(25% - #{rem($baseGap*2)});
	flex-basis: calc(25% - #{rem($baseGap*2)});
	margin: rem($baseGap);
	display: inline-block;
	transition: 300ms all;
	box-sizing: border-box;
}