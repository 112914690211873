// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
  // Default Link Styles
  padding: 1em rem($baseGap);
  text-decoration: none;
  color: $navColor;
  border-bottom: 1px solid rgba($dark, 0.3);
  // @include breakpoint(giant) {
  // 	font-size: rem(13px);
  // 	padding: rem(5px);
  //  border-bottom: 0;
  // }
  @include breakpoint(giant) {
    border-bottom: 0;
    font-size: rem(14px);
    padding: rem(10px) rem(5px);
  }
  @include breakpoint(full) {
    font-size: rem(16px);
    padding: rem(10px);
  }
  @include breakpoint(cut) {
    font-size: rem(16px);
    padding: rem(15px);
  }
}
%buttonHover {
  // Link Hover
  color: $light;
  background-color: $primary;
}
%buttonActive {
  // Link Active
  color: $dark;
  background-color: $secondary;
}
%prevIcon {
  // Icon to previus Submenu
  font-family: $iconFont;
  content: '\f105';
  transform: rotate(180deg);

  // @include breakpoint($break) {
  // 	transform:rotate(90deg);
  // }
}
%nextIcon {
  // Icon to next Submenu
  font-family: $iconFont;
  content: '\f105';

  @include breakpoint($break) {
    transform: rotate(90deg);
  }
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import '../modules/ruffnav';

// Navigation Styles
////
@include pageNavi {
  background-color: $navBg;
  z-index: 1010;

  nav {
    @include ul {
      margin-bottom: 100px;
      &.navi {
        li {
          @include navButtons {
            @include navButton;
          }
          @include hasSub {
            @include sub {
              background: $navBg;

              &:before {
                padding: 1em rem($baseGap);
                color: $dark;
                background-color: darken($navBg, 6%);
              }
            }
          }
          @include navBack {
            &:first-child {
            }
          }

          &.impressum,
          &.datenschutzerklaerung,
          &.agb,
          &.standorte,
          &.kontakt,
          &.hausordnung {
            display: none;
          }

          &.customData {
            .facebook {
              background: #3578e5;
              color: $light;
              .icon {
                margin-right: rem(10px);
              }
              &:hover {
                background: darken(#3578e5, 15%);
              }
            }
            .instagram {
              background: #ff0084;
              color: $light;
              .icon {
                margin-right: rem(10px);
              }
              &:hover {
                background: darken(#ff0084, 15%);
              }
            }
            .youtube {
              background: #ff0000;
              color: $light;
              .icon {
                margin-right: rem(10px);
              }
              &:hover {
                background: darken(#ff0000, 15%);
              }
            }
          }
        }
      }
    }
  }
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
  @include desktopVersionFunctionality;

  #navigation {
    top: 154px;
    transition: 300ms linear;
    &.scroll {
      top: 100px;
      ul.navi {
        transition: 300ms linear;
        padding: rem(10px) rem($baseGap);
      }
    }
    nav {
      max-width: rem($rowMaxWidth);
      width: 100%;
      margin: 0 auto;
      ul {
        margin-bottom: 0;
        &.navi {
          transition: 300ms linear;
          padding: rem(20px) rem($baseGap);
          justify-content: space-between;
          flex-wrap: wrap;
          @include breakpoint(full) {
            padding: rem(30px) rem($baseGap);
            justify-content: space-between;
          }

          li {
            &.customData {
              display: none;
            }
            &.hasSub {
              .sub {
              }
            }
            &.navBack {
            }
          }
        }
      }
    }
  }
}

// Quickbar Styles
////
#quickbar {
  background-color: darken($medium, 30%);
  color: $barColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem($baseGap);
  z-index: 1000;
  font-size: rem(16px);
  @include breakpoint($break) {
    display: none;
  }

  .mobileBranding {
    max-width: rem(150px);
  }

  .phone {
    .icon {
      background: $bgCheckbox;
      border-radius: 100%;
      padding: rem(10px) rem(12px);
    }
    span {
      display: none;
      @include breakpoint(small) {
        display: inline-block;
        margin-left: rem(10px);
      }
    }
  }
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 100%;
    flex-basis: 100%;
    display: inline-block;
    border-bottom: 1px solid rgba($dark, 0.3);
    @include breakpoint(medium) {
      flex-basis: auto;
      width: auto;
      border-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
    a {
      color: $dark;
      padding: rem(20px);
      display: inline-block;
      width: 100%;
      transition: 300ms linear;
      &:hover {
        background: $primary;
      }
      &.active {
        background: $secondary;
      }
      @include breakpoint(medium) {
        font-size: rem(14px);
        padding: rem(5px);
      }
      @include breakpoint(large) {
        font-size: rem(16px);
        padding: rem(15px);
      }
      @include breakpoint(giant) {
        font-size: rem($baseFontSize);
        padding: rem(20px);
      }
    }
  }
}

body.iphone {
  #navigation {
    nav {
      overflow-y: scroll;
      @include breakpoint(large) {
        overflow-y: auto;
      }
    }
  }
}
