* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
	background: $dark;
	box-sizing: border-box;
	color: $light;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	line-height: rem($baseLineHeight);
	scroll-behavior: smooth;
	font-weight: 400;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight);
	opacity: 1 !important;
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}



/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
	line-height:130%;
	text-transform: uppercase;
	font-weight: 900;
	span {
		display: block;
		text-transform: none;
		font-weight: 300;
	}

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	margin-bottom: rem(30px);
	font-size: rem(28px);
	line-height: rem(40px);
	span {
		font-size: 20px;
		line-height: 30px;
	}
	@include breakpoint(large) {
		margin-bottom: rem(50px);
		font-size: rem(32px);
		line-height: rem(42px);
		span {
			font-size: rem(25px);
			line-height: rem(35px);
			margin-top: rem(5px)
		}	
	}
	@include breakpoint(huge) {
		font-size: rem(45px);
		line-height: rem(45px);
		letter-spacing: 1px;
		span {
			font-size: rem(28px);
			line-height: rem(38px);
		}	
	}
}

h2,
.h2 {
	margin-bottom: rem(30px);
	font-size: rem(24px);
	line-height: rem(35px);
	span {
		font-size: 18px;
		line-height: 28px;
	}
	@include breakpoint(large) {
		margin-bottom: rem(50px);
		font-size: rem(28px);
		line-height: rem(40px);
		span {
			font-size: rem(22px);
			line-height: rem(32px);
			margin-top: rem(5px)
		}	
	}
	@include breakpoint(huge) {
		font-size: rem(30px);
		line-height: rem(40px);
		span {
			font-size: rem(25px);
			line-height: rem(35px);
		}	
	}
}

h3,
.h3 {
	margin-bottom: rem(30px);
	font-size: rem(20px);
	line-height: rem(30px);
	span {
		font-size: 18px;
		line-height: 28px;
	}
	@include breakpoint(large) {
		font-size: rem(22px);
		line-height: rem(32px);
	}
}

h4,
.h4 {
	margin-bottom: rem(40px);
    text-transform: none;
    font-size: rem(20px);
    line-height: rem(30px);
    font-weight: 700;
}

h5,
.h5 {
	font-size: rem($h5Size);
	margin-bottom: rem(40px);
	word-break: break-word;
}

h6,
.h6 {
	font-size: rem($h6Size);
	font-weight: 400!important;
	letter-spacing: 1px;
	margin-bottom: rem(15px);
}

/**
 * Links
 */
a {
	color: $primary;
	word-wrap: break-word;
	text-decoration: none;
	transition: 300ms linear;
	&:hover {
		color: $secondary;
	}
	&:focus {
		color: darken($secondary, 50%);
	}
	&:active {
		color: darken($secondary, 30%);
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0 2rem;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styledList {
		li {
			padding-left: rem(15px);
			margin-bottom: rem(15px);
			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				text-indent: rem(-15px);
			}

		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}

	&.flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			flex-basis: 100%;
			width: 100%;
			padding: rem(20px) rem(20px) rem(20px) rem(45px)!important;
			background: lighten($dark, 10%);
			margin-bottom: rem(10px);

			@include breakpoint(small) {
				flex-basis: 49%;
				width: 49%;
			}

			@include breakpoint(large) {
				width: 100%;
				flex-basis: 100%;
			}
			@include breakpoint(huge) {
				flex-basis: 49%;
				width: 49%;
				padding: rem(30px) rem(30px) rem(30px) rem(60px)!important;
			}
			
			&:before {
				@extend .icon;
				content: "\f00c"!important;
				text-indent: -30px!important;
				@include breakpoint(huge) {
					text-indent: rem(-30px)!important;
				}	
			}
			&.last {
				background: $primary;
				padding: 0!important;
				.btn {
					display: block!important;
					span {
						width: 80%;
					}
					&:after {
						width: 20%;
						float: right;
						text-align: center;
					}
				}
				&:before {
					display: none!important;
				}
			}
		}
	}

	&.flexList {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		li {
			flex-basis: 100%;
			width: 100%;
			@include breakpoint(small) {
				width: 49%;
				flex-basis: 49%;
			}
			@include breakpoint(cut) {
				width: 24%;
				flex-basis: 24%;
			}
		}
	}
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($medium, 0.8);
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	&.styledList
	{
		counter-reset: item;
	
		li {
			counter-increment: item;
	
			&:before {
				content: counters(item, ".") ". ";
			}
		}
	
		ol {
			margin-bottom: 0;
	
			li {
				&:before {
					content: counters(item, ".") " ";
					margin-right: rem(5px);
				}
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
	}
}

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($baseFontSize);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($baseGap) rem($baseGap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($baseGap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid $medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($baseGap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}