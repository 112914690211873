.medium {
  background: $light;
}
.margin {
  margin: rem(50px) 0;
  @include breakpoint(small) {
    margin: rem(80px) 0;
  }
  @include breakpoint(large) {
    margin: rem(120px) 0;
  }
  @include breakpoint(huge) {
    margin: rem(150px) 0;
  }
}
.padding {
  padding: rem(50px) 0;
  @include breakpoint(small) {
    padding: rem(80px) 0;
  }
  @include breakpoint(large) {
    padding: rem(120px) 0;
  }
  @include breakpoint(huge) {
    padding: rem(150px) 0;
  }
}
.paddingTop {
  padding-top: rem(50px);
  @include breakpoint(small) {
    padding-top: rem(80px);
  }
  @include breakpoint(large) {
    padding-top: rem(120px);
  }
  @include breakpoint(huge) {
    padding-top: rem(150px);
  }
}
.mediumpaddingTop {
  padding-top: rem(40px);
  @include breakpoint(small) {
    padding-top: rem(50px);
  }
  @include breakpoint(large) {
    padding-top: rem(60px);
  }
  @include breakpoint(huge) {
    padding-top: rem(70px);
  }
}
.smallpaddingTop {
  padding-top: rem(20px);
  @include breakpoint(small) {
    padding-top: rem(30px);
  }
  @include breakpoint(large) {
    padding-top: rem(40px);
  }
  @include breakpoint(huge) {
    padding-top: rem(50px);
  }
}
.mediumSpacer {
  margin: rem(2px) 0 rem(30px);
  display: inline-block;
  width: 100%;
}
.WidgetBannerContainer.StickyMiddleRight .WidgetBanner,
.WidgetBannerContainer.StickyBottomRight {
  bottom: rem(230px);
}

iframe.gutscheine-iframe {
  height: 100vh;
}
.coupon-form .coupon-value-item.selected {
  border: 2px solid #fff;
  color: white;
}
.coupon-form .coupon-template-item {
  margin: 10px;
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
  flex: 1;
  min-width: 200px;
  max-width: 200px;
  color: white;
}

iframe.nl-form {
  height: 23rem;
  border: 1px solid $medium;
}
.coupon-form .coupon-template-item.selected {
  border: 2px solid #fff;
}
.hint-text {
  border: 1px solid;
  padding: 20px;
  margin: 10px;
  color: white;
}
.coupon-form .coupon-template-item.selected {
  border: 2px solid #fff !important;
}
.form-result {
  border: 2px solid #16a765;
  padding: 20px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 3px;
  color: white;
}
iframe.dancecloudFrame {
  height: rem(450px);
}
body:not(.cmsBackend) .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    width: calc(50% - 18px);
    flex-basis: auto;
    float: left;
    margin-bottom: rem($baseGap * 2);
    img {
      width: 100%;
    }
    @include breakpoint(huge) {
      width: calc(25% - 18px);
    }
  }
}

.croppedImage {
  display: inline-block;
  width: 49%;
  float: left;
  margin-bottom: rem($baseLineHeight);
}
.z-index {
  z-index: 1000;
}
.mediumSpacer {
  margin: 15px auto;
}
hr.spaceLine {
  margin: rem(32px) rem(18px) rem(50px);
}

img.image {
  margin-bottom: rem($baseLineHeight);
  display: inline-block;
  width: 100%;
  @include breakpoint(tiny) {
    width: calc(50% - #{rem($baseGap)});
    &:nth-child(odd) {
      margin-right: rem($baseGap);
    }
  }
  @include breakpoint(medium) {
    width: calc(33.3333% - #{rem($baseGap)});
    &:nth-child(odd) {
      margin-right: rem($baseGap);
    }
  }
  @include breakpoint(large) {
    width: 100%;
  }
}
.spacerTop {
  margin-top: rem(50px);
  @include breakpoint(large) {
    margin-top: rem(80px);
  }
}
.spacer {
  display: inline-block;
  width: 100%;
  margin: rem(32px) 0 rem(50px);
  @include breakpoint(large) {
    margin: rem(52px) 0 rem(80px);
  }
}

.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $secondary;
}
.highlight-primary {
  color: $primary;
}

.btn {
  color: $dark;
  text-decoration: none;
  font-weight: 700;
  transition: 300ms linear;
  display: inline-block;
  width: auto;
  span {
    float: left;
    background: $primary;
    padding: rem(20px);
    display: inline-block;
    transition: 300ms linear;
    font-size: rem(16px);
    @include breakpoint(large) {
      padding: rem(30px);
    }
  }
  &:after {
    @extend .icon;
    content: '\f105';
    display: block;
    line-height: rem($baseLineHeight);
    background: $secondary;
    padding: rem(20px);
    float: left;
    color: $dark;
    transition: 300ms linear;
    font-weight: 400;
    font-size: rem(16px);
    @include breakpoint(large) {
      padding: rem(30px);
    }
  }

  &:hover,
  &:focus {
    span {
      background: lighten($dark, 20%);
      color: $light;
    }

    &:after {
      background: lighten($dark, 30%);
      color: $light;
    }
  }
}
.pdfBtn {
  color: $dark;
  text-decoration: none;
  font-weight: 700;
  transition: 300ms linear;
  display: inline-block;
  width: auto;
  span {
    background: $primary;
    padding: rem(20px);
    display: inline-block;
    transition: 300ms linear;
    font-size: rem(16px);
    @include breakpoint(medium) {
      padding: rem(30px);
    }
  }
  &:before {
    @extend .icon;
    @extend .icon-pdf;
    display: block;
    line-height: rem($baseLineHeight);
    background: $secondary;
    padding: rem(20px);
    float: left;
    color: $dark;
    transition: 300ms linear;
    font-weight: 400;
    font-size: rem(16px);
    @include breakpoint(medium) {
      padding: rem(30px);
    }
  }

  &:hover,
  &:focus {
    span {
      background: lighten($dark, 20%);
      color: $light;
    }

    &:before {
      background: lighten($dark, 30%);
      color: $light;
    }
  }
}
.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;

  body.onlineanmeldung & {
    span {
      font-size: rem(15px);

      @include breakpoint(small) {
        font-size: rem(16px);
      }

      @include breakpoint(giant) {
        font-size: rem(15px);
        padding: rem(30px) rem(24px);
      }
    }
  }
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: 'default:' + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content +
        '....' +
        $point +
        ':' +
        nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: '#{$content}';
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(65px);
  left: rem(40px);
  z-index: 999999;
  visibility: hidden;
  display: block;

  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:first-child):not(.hide).transitionIn {
    margin-top: $baseGap;
  }

  .notification {
    display: block;
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding: 0;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    visibility: hidden;
    opacity: 0;
    max-height: none;
    height: 0;
    box-sizing: border-box;
    margin-left: -100px;

    .noteContent {
      padding: 1rem;
      display: block;
    }

    @include breakpoint(large) {
      font-size: rem(16px);
      line-height: rem(22px);
    }

    &.transitionIn {
      visibility: visible;
      opacity: 1;
      max-height: 9999999px;
      height: auto;
      margin-left: 0;
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;

      &,
      .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert;
    }
    &.success,
    &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      margin-left: -100px;
      opacity: 0;
      visibility: hidden;
      height: 0;
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content: '';
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999998;
  }
  &:after {
    content: '';
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index: 99999999;
    filter: invert(100%);
  }
}

body.cmsBackend {
  .cmsDpNone {
    display: none;
  }
}
ul.navi {
  li {
    &:last-of-type {
      padding-bottom: 5rem;
      @include breakpoint(giant) {
        padding-bottom: 0;
      }
    }
  }
}
