$amount: 2;
$iconBg: $dark;
$iconColor: $light;
$contactsBg: lighten($dark, 15%);;
$contactsColor: $light;
$desktopPosition: 200px;

//////////////////////////////////////////////////////////////// 

@import "../modules/fixedButtonsMixins";

.fixedContacts {
	>li {
		&:before {
			@include before;
			background: lighten($dark, 15%);;	
			color: $iconColor;
		}

		// Icon Examples

		&.erlaa {
			&:before {
				content: "Standort";
				font-size: rem(14px);
				border-right: 1px solid rgba($light,.3);
				@include breakpoint(small) {
					font-size: rem(16px);
				}
			}
		}
	}

	// Mobile Styles
	@include breakpoint(giant, max) {
		@include mobileFixedContacts;
		overflow: visible;
		border-radius: 5px;
		box-shadow: 6px -2px 15px rgba(0,0,0, .35);
		background: lighten($dark, 15%);
		>li {
			@include mobileLi;
			background: $iconBg;
			cursor: pointer;
			overflow:visible;

			&:before {
				@include mobileBefore;
				cursor: pointer;
			}
			
			// Content on Hover/Tab
			.inner {
				@include mobileInner;					
				padding: rem(30px) rem($baseGap);
				box-shadow: 6px -2px 15px rgba(0,0,0, .35);
				background: $contactsBg;
				color: $contactsColor;
				
				.phone {
					display: block;
					height: 100%;
					width: auto;
				}
			}
			
			// Hover usally does not need to be adjusted
			&:hover {
				.inner {
					opacity: 1;
					visibility: visible;
					transform: translate(0, -66px); // If the height of the bar is adjusted, you also need to adjust the transform
				}
			}

			&.hide {
				opacity: 0;
				transform: translate(0, -50px);
			}

			// Elements without Tab/Hover
			&.mobileNoHover {
				.inner {
					@include mobileNohoverInner;

					a {
						@include coverLink
					}
				}
			}
			
			&.mobileNoHover {
				// Define icon size e.g. social ícon
				&:before {
					max-width: 16px;
				}
			}
		}
	}

	// Break styles
	@include breakpoint(giant) {
		display: none;
		
	}
}